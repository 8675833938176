<template>
  <div class="submit-form" style="max-width: 400px">
    <div>
      <h1>{{ $t('resetPassword') }}</h1>
      <b-row align-h="center" style="margin-bottom: 5px">
        <b-col class="text-left" sm="12">
          <label :for="'password'">Password:</label>
        </b-col>
        <b-col sm="12">
          <b-form-input :state="passwordState"
                        required
                        :id="'password'"
                        :type="'password'"
                        v-model="user.password"
                        aria-describedby="password-feedback">
          </b-form-input>
          <b-form-invalid-feedback id="password-feedback">
            {{ $t('pleaseEnterPassword') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row align-h="center" style="margin-bottom: 5px">
        <b-col class="text-left" sm="12">
          <label :for="'passwordCheck'">{{ $t('register.confirmPassword') }}</label>
        </b-col>
        <b-col sm="12">
          <b-form-input :state="passwordCheckState"
                        required
                        :id="'passwordCheck'"
                        :type="'password'"
                        v-model="passwordCheck"
                        aria-describedby="passwordCheck-feedback">
          </b-form-input>
          <b-form-invalid-feedback id="passwordCheck-feedback">
            $t('resetPassword')
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <button @click="saveUser" class="btn btn-success mt-3">{{ $t('register.passwordDontMatch') }}</button>
    </div>
  </div>
</template>

<script>
import UserDataService from "../services/UserDataService";

export default {
  name: "passwordReset",
  data() {
    return {
      user: {
        id: null,
        eMail: "",
        password: "",
        name: "",
        zip: "",
        street: "",
        city: "",
        country: "IT",
        newsletter: 0,
      },
      firstName: '',
      lastName: '',
      passwordCheck: '',
      submitted: false,
      acceptTerms: false,
      mailErrorText: 'The email is already taken',
      mailError: false,
      registerSuccess: false,
    };
  },
  computed: {
    passwordState() {
      return this.user.password.length <= 0 && this.submitted ? false : null
    },
    passwordCheckState() {
      return this.user.password === this.passwordCheck ? null : false
    },
  },
  methods: {
    saveUser() {
      this.submitted = true;
      if (this.passwordState == null) {
        let data = {
          password: this.user.password,
          pasCode: this.$route.params.pasCode,
          lang: this.$i18n.locale,
        };
        UserDataService.resetPassword(data)
            .then(response => {
              console.log(response)
              if(response.data === "success"){
                let params = {
                  msg: this.$i18n.messages[this.$i18n.locale].passwordChangeSuccess
                }
                this.$router.push({name: 'home', params});
              }
            })
            .catch(e => {
              console.log(e);
              console.log(e.response);
            });
      }
    },
  },
  mounted() {
    console.log("MOUNTED");
    console.log(this.$route.params.pasCode);
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
